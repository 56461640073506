.card {
    background-color: #fff;
    padding: 24px;
    box-sizing: border-box;
    width: 628px;
    display: flex;
    align-items: center;
    border-radius: 8px;

    .icon {
        margin-right: 24px;
        display: block;
        width: 143px;
    }

    .title {
        color: #101010;

        /* H4 */
        font-family: "Public Sans";
        font-size: 22.4px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        /* 26.88px */
    }

    .content {
        color: #666;

        /* Body 1 Regular */
        font-family: "Public Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 22.4px */
        margin-top: 8px;
        padding-right: 8px;
    }
}

.animateCardRoot {
    height: 136px;
}

.animateCard {
    background-color: #fff;
    width: 410px;
    height: 96px;
    box-sizing: border-box;
    border-radius: 8px;
    margin-top: 24px;
    will-change: height;
    transition: height .3s ease-in-out;
    position: relative;
    overflow: hidden;

    &:hover {
        height: 136px;
    }

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        border: 1px solid #FD4C00;
        top: 0;
        left: 0;
        box-sizing: border-box;
        border-radius: 8px;
        opacity: 0;
        will-change: opacity;
        transition: opacity .3s ease-in-out;
    }

    .icon {
        display: block;
        width: 60px;
        height: 48px;
    }

    .title {
        color: #101010;

        /* H4 */
        font-family: "Public Sans";
        font-size: 22.4px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        /* 26.88px */
    }

    .firstFrame {
        padding: 24px;
        display: flex;
        align-items: center;
        gap: 24px;
        will-change: opacity, transform;
        transition: opacity 0.4s ease-in-out, transform 0.4s ease-in;
        transition-delay: 0.05s;
    }

    &:hover .firstFrame {
        opacity: 0;
        transform: translateY(-10px);
    }

    .secondFrame {
        color: #666;
        font-family: "Public Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 22.4px */
        padding: 24px;
        opacity: 0;
        transform: translateY(20px);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 136px;
        will-change: opacity, transform;
        transition: opacity 0.4s ease-in-out, transform 0.5s ease-out;
        transition-delay: 0.2s;
        background-repeat: no-repeat;
        background-position: right 10px center;
        background-size: auto 105px;
        box-sizing: border-box;
    }

    &:hover .secondFrame {
        opacity: 1;
        transform: translateY(0px);
    }
}

.animateCard:hover::after {
    opacity: 1;
}

@media screen and (max-width: 992px) {
    .card {
        background-color: #fff;
        padding: 24px;
        box-sizing: border-box;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: start;
        border-radius: 8px;

        .icon {
            margin-right: 0;
            display: block;
            width: 143px;
        }

        .title {
            margin-top: 24px;
            color: var(--Neutral-color-100, var(--Woodsmoke, #101010));

            /* Mobile/H4 */
            font-family: "Public Sans";
            font-size: 22.4px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            /* 26.88px */
        }

        .content {
            color: var(--Neutral-color-60, #666);

            /* Mobile/Body1 Regular */
            font-family: "Public Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            /* 22.4px */
            /* 22.4px */
            margin-top: 8px;
        }
    }

    .mobileCard {
        width: 290px;
        height: 303px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 24px;
        flex-shrink: 0;
        border-radius: 8px;
        padding: 24px;
        background-color: #fff;

        .icon {
            width: 60px;
            height: 48px;
        }

        .title {
            color: var(--Neutral-color-100, var(--Woodsmoke, #101010));

            /* H4 */
            font-family: "Public Sans";
            font-size: 22.4px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            /* 26.88px */
        }

        .content {
            color: var(--Neutral-color-60, #666);

            /* Body 1 Regular */
            font-family: "Public Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            /* 22.4px */
        }
    }
}