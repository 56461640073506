.root {
    width: 1280px;
    padding: 100px 0;
    margin: 0 auto;

    .contentWrapper {
        display: flex;
        justify-content: space-between;
        align-items: end;

        .left {
            width: 1000px;
        }

        .title {
            margin-top: 0;
            width: 522px;
        }

        .button {
            width: 160px;
        }
    }

    .cardWrapper {
        margin-top: 64px;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .innerCardWrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
}

@media screen and (max-width: 992px) {
    .root {
        width: 100%;
        padding: 80px 0;
        margin: 0 auto;

        .contentWrapper {
            display: flex;
            flex-direction: column;
            justify-content: start;
            align-items: center;

            .left {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
            }

            .button {
                margin-top: 20px;
                width: 184px;
                line-height: 52px;
            }
        }

        .cardWrapper {
            margin-top: 40px;
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 16px;
        }

        .innerCardWrapper {
            display: flex;
            justify-content: space-between;
            width: 100%;
            padding: 0 20px;
            box-sizing: border-box;
            overflow-x: scroll;
            gap: 16px;
            margin-top: 16px;

            &::-webkit-scrollbar {
                display: none;
                height: 0;
            }
        }
    }
}