.global-logo-wrapper {
    width: 160px;
    display: flex;
    align-items: center;
    gap: 5px;

    .global-logo {
        width: 32px;
        height: 32px;
    }

    .global-logo-text {
        width: 112px;
        height: 32px;
    }

    &.pointer {
        cursor: pointer;
    }
}

@media screen and (max-width: 992px) {
    .global-logo-wrapper {
        width: auto;

        .global-logo {
            width: 20px;
            height: 20px;
        }

        .global-logo-text {
            width: 83px;
            height: auto;
        }
    }
}