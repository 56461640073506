@import "src/styles/constant.scss";

.header {
  --bgReactangleSize: 26.35px;
  --bgReactangle1LeftOrgin: 575.74px;
  --bgReactangle1LeftTarget: 451.74px;
  --bgReactangleExtension1Width: 232px;
  --bgReactangleExtension1Height: 227px;

  --bgReactangle2Bottom: 595.42px;
  --bgReactangle2LeftOrgin: 428.68px;
  --bgReactangle2LeftTarget: 508.68px;
  --bgReactangleExtension2Width: 200.49px;
  --bgReactangleExtension2Height: 241.74px;

  --bgReactangle3LeftOrgin: 716px;
  --bgReactangle3LeftTarget: 634.7px;
  --bgReactangle3BottomOrgin: 595.15px;
  --bgReactangle3BottomTarget: 695.09px;
  --bgReactangleExtension3Width: 267.51px;
  --bgReactangleExtension3Height: 441.08px;
}
@media (max-width: 600px) {
  .header {
    --bgReactangleSize: 26.64px;

    --bgReactangleExtension1Width: 208.15px;
    --bgReactangleExtension1Height: 203.65px;

    --bgReactangle2Bottom: 534.2px;
    --bgReactangle2LeftOrgin: 382.38px;
    --bgReactangle2LeftTarget: 456.38px;
    --bgReactangleExtension2Width: 179.88px;
    --bgReactangleExtension2Height: 216.88px;

    --bgReactangle3LeftOrgin: 638px;
    --bgReactangle3LeftTarget: 569.45px;
    --bgReactangle3BottomOrgin: 534.36px;
    --bgReactangle3BottomTarget: 623.63px;
    --bgReactangleExtension3Width: 239.89px;
    --bgReactangleExtension3Height: 396.05px;
  }
}
@keyframes bgRectangleA1 {
  0% {
    left: 575.74px;
  }
  33.33% {
    left: 451.74px;
  }
  100% {
    left: 451.74px;
  }
}

@keyframes bgRectangleA2 {
  0% {
    left: 428.68px;
  }
  33.33% {
    left: 508.68px;
  }
  100% {
    left: 508.68px;
  }
}
@keyframes bgRectangleA2S {
  0% {
    left: 382.38px;
  }
  33.33% {
    left: 456.38px;
  }
  100% {
    left: 456.38px;
  }
}
@keyframes bgRectangleA3 {
  0% {
    left: 716px;
    bottom: 595.15px;
  }
  33.33% {
    left: 634.7px;
    bottom: 695.09px;
  }
  100% {
    left: 634.7px;
    bottom: 695.09px;
  }
}
@keyframes bgRectangleA3S {
  0% {
    left: 638px;
    bottom: 534.36px;
  }
  33.33% {
    left: 569.45px;
    bottom: 623.63px;
  }
  100% {
    left: 569.45px;
    bottom: 623.63px;
  }
}
@keyframes bgRectangleExtensionA1 {
  0%,
  33.33% {
    transform: scale(0);
    top: 0;
    left: calc(
      -1 * (var(--bgReactangleExtension1Width) - var(--bgReactangleSize))
    );
  }
  66.66%,
  100% {
    transform: scale(1);
    top: var(--bgReactangleSize);
    left: calc(-1 * var(--bgReactangleExtension1Width));
  }
}
@keyframes bgRectangleExtensionA2 {
  0%,
  33.33% {
    transform: scale(0);
    top: calc(
      -1 * (var(--bgReactangleExtension2Height) - var(--bgReactangleSize))
    );
    left: calc(
      -1 * (var(--bgReactangleExtension2Width) - var(--bgReactangleSize))
    );
  }
  66.66%,
  100% {
    transform: scale(1);
    top: calc(-1 * var(--bgReactangleExtension2Height));
    left: calc(-1 * (var(--bgReactangleExtension2Width)));
  }
}
@keyframes bgRectangleExtensionA3 {
  0%,
  33.33% {
    transform: scale(0);
    top: 0;
    left: 0;
  }
  66.66%,
  100% {
    transform: scale(1);
    // top: var(--bgReactangleSize);
    // left: var(--bgReactangleSize);
    top: calc(var(--bgReactangleSize) - 2px);
    left: calc(var(--bgReactangleSize) - 2px);
  }
}
@keyframes contentWrapperA {
  0% {
    background: transparent;
    backdrop-filter: unset;
    -webkit-backdrop-filter: unset;
  }
  66.66% {
    background: transparent;
    backdrop-filter: unset;
    -webkit-backdrop-filter: unset;
  }
  66.67% {
    background: rgba(255, 255, 255, 0.07);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
  100% {
    background: rgba(255, 255, 255, 0.07);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
  }
}
@mixin bgRectangle {
  position: absolute;
  overflow: visible;
  width: var(--bgReactangleSize);
  height: var(--bgReactangleSize);
  box-sizing: border-box;
  background: #fd4c00;
  border: 1px solid #101010;
  animation: bgRectangleA1 4.8s ease infinite;
}
@mixin bgRectangleExtension {
  position: absolute;
  transform: scale(0);
  animation: bgRectangleExtensionA1 4.8s ease infinite;
}

.header {
  background-color: #fd4c00;
  padding-top: 72px;
  padding-top: 80px;
  height: 860px;
  position: relative;
  overflow: hidden;
  .container {
    position: relative;
    width: 1280px;
    margin: 0 auto;
  }
  .headerBGWrapper {
    position: absolute;
    width: 1192px;
    height: 1192px;
    top: -283px;
    left: 334px;
    z-index: 1;
    overflow: visible;
    .headerBG {
      position: absolute;
      width: 100%;
      height: 100%;
      background: url("./header-bg.svg") no-repeat;
      background-size: 100% 100%;
      top: 0;
      left: 0;
    }
    .bgRectangle1 {
      @include bgRectangle;
      bottom: 486.31px;
      .bgRectangleExtension1 {
        @include bgRectangleExtension;
        width: var(--bgReactangleExtension1Width);
        height: var(--bgReactangleExtension1Height);
        transform-origin: 100% 0%;
      }
    }
    .bgRectangle2 {
      @include bgRectangle;
      bottom: var(--bgReactangle2Bottom);
      left: 575.74px;
      animation-name: bgRectangleA2;

      .bgRectangleExtension2 {
        @include bgRectangleExtension;
        animation-name: bgRectangleExtensionA2;
        width: var(--bgReactangleExtension2Width);
        height: var(--bgReactangleExtension2Height);
        transform-origin: 100% 100%;
        transform: scale(0);
      }
    }
    .bgRectangle3 {
      @include bgRectangle;
      animation-name: bgRectangleA3;
      // animation: none;
      // left: 634.7px;
      // bottom: 695.09px;
      .bgRectangleExtension3 {
        @include bgRectangleExtension;
        animation-name: bgRectangleExtensionA3;
        // animation: none;
        // transform: scale(1);
        // top:0;
        // left:0;
        // top: var(--bgReactangleSize);
        // left: var(--bgReactangleSize);
        width: var(--bgReactangleExtension3Width);
        height: var(--bgReactangleExtension3Height);
        transform-origin: 0% 0%;
        transform: scale(0);
      }
    }
  }

  .contentWrapper {
    border-radius: 8px;
    border: 2px solid #fff;
    background: rgba(238, 238, 238, 0.07);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    // animation: contentWrapperA 4.8s ease infinite ;
    padding: 38px 58px;
    margin-top: 130px;
    box-sizing: border-box;
    width: 686px;
    position: relative;
    z-index: 2;
  }

  .content {
    width: 566px;
    color: #fff;
    flex-direction: column;
    font-family: Play;
  }
  .title {
    font-size: 64px;
    line-height: 70px;
    letter-spacing: -0.32px;
    -webkit-text-stroke-width: 0.6px;
    -webkit-text-stroke-color: #efe4d2;
    stroke-width: 0.6px;
    stroke: #efe4d2;
    // width: 480px;
  }
  .subTitle {
    font-size: 24px;
    font-weight: normal;
    line-height: 28px;
    display: flex;
    align-items: center;
    letter-spacing: -0.12px;
    color: #ffffff;

    margin-top: 24px;
  }
  .desc {
    font-family: Public Sans;
    font-weight: 400;
    font-size: 16px;
    font-weight: normal;
    line-height: 24px;

    letter-spacing: -0.08px;
    color: rgba(255, 255, 255, 0.8);
    margin-top: 16px;
    > a {
      text-decoration: underline;
      font-weight: 500;
    }
  }

  .btnWrapper {
    margin-top: 40px;
    gap: 16px;
  }

  .btn {
    width: 184px;
    box-sizing: border-box;
    line-height: 52px;
  }

  .rectangle {
    border: 2px solid rgba(255, 255, 255, 0.5);
    background: rgba(255, 255, 255, 0.07);
    position: absolute;
    box-sizing: border-box;

    &.small {
      backdrop-filter: blur(10px);
      border-radius: 6px;
      width: 52px;
      height: 52px;
      top: -51px;
      right: -52px;
    }

    &.big {
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      border-radius: 10px;
      width: 114px;
      height: 114px;
      bottom: -118px;
      left: -114px;
    }
  }
}

@media screen and (max-width: 992px) {
  .header {
    // min-height: calc(100vh - 64px);
    height: 848px;
    box-sizing: border-box;
    padding-top: 128px;
    .headerBGWrapper {
      width: 1069px;
      height: 1269px;
      top: -64px;
      top: -46px;
      left: -262px;
      .headerBG {
        background: url("./header-bgS.svg") no-repeat;
        // background-size: 100vw 217.44vw;
        // width: 100vw;
        // height: 217.44vw;
      }
      .bgRectangle1 {
        display: none;
      }
      .bgRectangle2 {
        animation-name: bgRectangleA2S;
      }
      .bgRectangle3 {
        animation-name: bgRectangleA3S;
      }
    }

    .container {
      width: 100%;
      height: auto;
      left: 0;
      padding-bottom: 200px;
    }

    .contentWrapper {
      border-radius: 7px;
      border: 2px solid #fff;
      padding: 30px 30px 30px 36px;
      margin-left: -6px;
      margin-top: 0px;
      width: 310px;
      // height: 503px;
      box-sizing: border-box;
    }

    .content {
      width: 240px;
      // height: 287px;
      color: #fff;

      overflow: hidden;
    }
    .title {
      font-family: Play;
      font-size: 44px;
      font-weight: normal;
      line-height: 48px;
      display: flex;
      align-items: center;
      letter-spacing: -0.2px;
      /* 外部/Neutral color/white */
      color: #ffffff;
      width: 100%;
    }
    .subTitle {
      font-family: Play;
      font-size: 18px;
      font-weight: normal;
      line-height: 22px;
      display: flex;
      align-items: center;
      letter-spacing: -0.09px;
      color: #ffffff;
      margin-top: 24px;
    }
    .desc {
      font-size: 12px;
      font-weight: normal;
      line-height: 110%;
      letter-spacing: -0.06px;
      color: rgba(255, 255, 255, 0.8);
      margin-top: 12px;

      font-size: 12px;
      font-weight: normal;
      line-height: 16px;
      letter-spacing: -0.06px;
      color: rgba(255, 255, 255, 0.8);
      > a {
        font-weight: 500;
        font-size: 12px;
      }
    }

    .btnWrapper {
      flex-direction: column;
      margin-top: 40px;
    }

    .btn {
      width: 240px;
      box-sizing: border-box;
      line-height: 44px;
    }

    .rectangle {
      &.small {
        backdrop-filter: none;
        border-radius: 7px;
        width: 46px;
        height: 46px;
        top: -45px;
        right: -45px;
      }
    }
  }
}
