.root {
    background-color: #FD4C00;
    height: 0;
    transition: height .2s ease-out;
    will-change: height;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 888;

    &.whiteTheme {
        background-color: #fff;
    }

    &.open {
        height: 100vh;
    }

    .menu {
        display: flex;
        width: 100%;
        flex-grow: 1;
        flex-direction: column;
        align-items: center;
        justify-content: start;
        gap: 40px;
        margin: 0 auto;
        margin-top: 96px;
        overflow-y: scroll;
        &::-webkit-scrollbar {
            display: none;
            height: 0;
        }
    }

    .btnWrapper {
        flex-shrink: 0;
        margin-top: 24px;
        margin-bottom: 36px;
    }

    .btn {
        margin: 0 auto;
        width: 240px;
        line-height: 52px;
    }
}

.menuItem {
    color: #FFF;

    &.dark {
        color: #101010;
    }

    .title {
        text-align: center;
        font-family: "Public Sans";
        font-size: 22.4px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        margin-bottom: 16px;
    }

    .options {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }

    .item {
        display: flex;
        align-items: center;
        gap: 8px;

        &>div {
            text-align: center;

            /* Mobile/Body1 Regular */
            font-family: "Public Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            /* 22.4px */
        }

        .icon {
            width: 16px;
            height: 16px;
        }
    }
}

@supports (-webkit-touch-callout: none) {
    .root.open {
        height: -webkit-fill-available;
    }
}
