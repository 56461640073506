.root {
  background: #efe4d2;
  padding: 100px 0;

  .container {
    width: 1250px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title {
    margin-top: 16px;
  }

  .tabWrapper {
    display: flex;
    justify-content: space-between;
    width: 1181px;
    margin: 48px auto 0;
    .myTabBar {
      &:nth-child(2) {
        .myTabBar {
          width: 248px;
        }
      }
      &:nth-child(3) {
        .myTabBar {
          width: 232px;
        }
      }
      &:nth-child(4) {
        .myTabBar {
          width: 224px;
        }
      }
      &:nth-child(1) {
        .myTabBar {
          width: 220px;
        }
      }
    }
  }

  .swiper {
    width: 1062px;
    height: 448px;
    margin-top: 48px;
  }
}

@media screen and (max-width: 992px) {
  .root {
    background: #efe4d2;
    padding: 80px 0;

    .container {
      width: 100%;
      padding: 0;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .title {
      margin: 0 20px;
      margin-top: 16px;
    }

    .tabWrapper {
      display: block;
      width: 100%;
      margin: 40px auto 0;
      .myTabBar {
        // &:nth-child(3) {
          .myTabBar {
            width: 252px !important;
          }
        // }
      }
    }

    .swiperItem {
      display: flex;
      justify-content: center;
    }

    .swiper {
      width: 100%;
      height: auto;
      //   height: 800px;
      margin-top: 40px;

      :global(.swiper-slide) {
        width: 322px;
      }
      :global(.swiper-slide:first-of-type) {
        padding-left: 20px;
      }
      :global(.swiper-slide:last-of-type) {
        padding-right: 20px;
      }
    }
  }
}
