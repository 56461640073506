.root {
    min-width: 100px;
    position: relative;

    .sectionTitle {
        color: #101010;

        /* H2 */
        -webkit-text-stroke-width: 0.6px;
        -webkit-text-stroke-color: #101010;
        font-family: Play;
        font-size: 56px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        /* 61.6px */
        letter-spacing: -0.28px;
        position: absolute;
        left: 0;
        top: 0;

        &.block {
            position: relative;
            visibility: hidden;
        }
    }
}

@media screen and (max-width: 992px) {
    .root {
        min-width: 100px;
        position: relative;

        .sectionTitle {
            color: var(--Neutral-color-100, var(--Woodsmoke, #101010));
            text-align: center;

            /* Mobile/H2 */
            -webkit-text-stroke-width: 0.4px;
            -webkit-text-stroke-color: var(--Neutral-color-100, var(--Woodsmoke, #101010));
            font-family: Play;
            font-size: 35.2px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%;
            /* 38.72px */
            letter-spacing: -0.176px;

            &.block {
                position: relative;
                visibility: hidden;
            }
        }
    }
}