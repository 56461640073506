.root {
    width: 24px;
    height: 24px;
    padding: 2px;
    box-sizing: border-box;
    position: relative;

    .line {
        position: absolute;
        width: 20px;
        height: 2px;
        background-color: #fff;
        border-radius: 1px;
    }

    &.dark .line {
        background-color: #101010;
    }

    .line1 {
        top: 5px;
        left: 2px;
        will-change: height, width;
        transition: height .2s ease-in-out, width .2s ease-in-out;
    }
    .line2 {
        left: 2px;
        top: 11px;
        transition: transform;
        transition: transform .2s ease-in-out;
        transform-origin: center;
    }
    .line3 {
        bottom: 5px;
        right: 2px;
        will-change: height, width;
        transition: height .2s ease-in-out, width .2s ease-in-out;
    }

    &.closed {
        .line1 {
            width: 0;
        }
        .line3 {
            width: 0;
        }
        .line2_1 {
            transform: rotate(45deg);
        }
        .line2_2 {
            transform: rotate(-45deg);
        }
    }
}