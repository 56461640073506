.root {
  width: 1280px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0px;
  padding: 80px 0 80px 0;
  .left {
    .img {
      width: 589px;
      height: 589px;
      box-sizing: border-box;
      .video {
        width: 100%;
        height: 100%;
        pointer-events: none;
      }
    }
  }
  .right {
    width: 568px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    .title {
      width: 100%;
      .sectionTitle {
        font-family: Play;
        font-size: 56px;
        font-weight: normal;
        line-height: 110%;
        letter-spacing: -0.28px;
        color: #101010;
      }
    }
    .desc {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 12px;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      font-family: Public Sans;
      color: rgba(16, 16, 16, 0.6);
    }

    .contentItems {
      display: flex;
      flex-direction: column;
      gap: 16px;
      width: 100%;
      .contentItem {
        width: 100%;
        height: 140px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 28px;
        background: #fff;
        border-radius: 8px;
        border: 1px solid #fff;
        &:hover {
          border-color: #fd4c00;
        }
        .contentItemRight {
          width: 438px;
          display: flex;
          flex-direction: column;
          gap: 8px;
          .contentItemTitle {
            font-family: Public Sans;
            font-size: 22.5px;
            font-weight: normal;
            line-height: 120%;
            letter-spacing: 0px;
            color: #000000;
            font-weight: 700;
          }
          .contentItemDesc {
            font-family: Public Sans;
            font-size: 16px;
            font-weight: normal;
            line-height: 140%;
            letter-spacing: 0px;
            color: rgba(0, 0, 0, 0.6);
          }
        }
      }
    }
    .button {
      width: 160px;
      line-height: 48px;
    }
  }
}

@media screen and (max-width: 992px) {
  .root {
    width: 350px;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    // padding-right: 80px;
    padding: 80px 0 20px 0;
    gap: 0px;
    .left {
      .img {
        margin-left: 0;
        // padding: 0 20px;
        width: 100vw;
        height: 100vw;
        box-sizing: border-box;
        margin-right: 0;
        order: 2;
        .video {
          width: 100%;
          height: 100%;
          pointer-events: none;
        }
      }
    }
    .right {
      width: 100%;
      overflow-wrap: break-word;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      .title {
        width: 100%;
        text-align: center;
      }
      .desc {
        width: 100%;
        text-align: center;
        align-items: center;

        gap: 8px;
      }
      .contentItems {
        width: 100%;
        gap: 16px;
        .contentItem {
          width: 100%;
          height: 132px;
          gap: 24px;
          .contentItemRight {
            width: 243px;
          }
        }
      }
    }
  }
}
