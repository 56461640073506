.root {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 62px 0;

    .wrapper {
        width: 548px;
    }

    .title {
        margin-top: 12px;
    }

    .content {
        margin-top: 32px;
        min-height: 66px;
    }

    .footer {
        display: flex;
        gap: 16px;
        margin-top: 32px;
    }

    .btn {
        width: 160px;
        line-height: 44px;
    }

    .img {
        width: 568px;
        height: 520px;
        background-image: url(./assets/build-together.png);
        background-position: center;
        background-size: 725px 642px;
    }
}

@media screen and (max-width: 992px) {
    .root {
        width: 100%;
        margin: 0 auto;
        display: flex;
        flex-direction: column-reverse;
        justify-content: center;
        align-items: center;
        padding: 80px 20px 40px 20px;
        box-sizing: border-box;
        gap: 40px;
    
        .wrapper {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    
        .title {
            margin-top: 16px;
        }
    
        .content {
            margin-top: 24px;
            min-height: 110px;
        }
    
        .footer {
            display: flex;
            gap: 16px;
            margin-top: 24px;
        }
    
        .btn {
            width: 160px;
            line-height: 52px;
        }
    
        .img {
            width: 350px;
            height: 350px;
            background-image: url(./assets/build-together2.png);
            background-position: center;
            background-size: 350px 350px;
        }
    }
}