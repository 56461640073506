.root {
    background-color: #E44400;
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
    position: fixed;
    overflow: hidden;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 999;
    transition: background-color .2s ease-out;
    will-change: background-color;

    &.whiteTheme {
        background-color: #fff;
    }

    .wrapper {
        height: 72px;
        width: 1280px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .menu {
        height: 100%;
        gap: 48px;
    }

    .rightButton {
        width: 150px;
    }

    .dropdownLabel {
        font-size: 14.4px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 17.28px */
        user-select: none;
    }
}

@media screen and (max-width: 992px) {
    .root {
        background-color: #FD4C00;

        .wrapper {
            height: 64px;
            width: 350px;
        }
    }
}