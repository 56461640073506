.root {
    cursor: pointer;
    user-select: none;
    gap: 8px;
}

.icon {
    will-change: transform;
    transition: transform .2s ease-in-out;
    transform-origin: center;

    &.active {
        transform: rotate(180deg);
    }

    svg {
        display: block;
        width: 25px;
        height: 24px;
    }

    &.black {
        svg path {
            fill: #000000;
        }
    }
}

.menuContainer {
    display: flex;
    padding: 8px;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.50);
    background: #E44400;

    &.active {
        border: 1px solid #E5E5E5;
        background-color: #fff;

        .menuItem {
            color: #101010;

            &:hover {
                background: #f8f8f8;
            }
        }
    }

    .menuItem {
        color: #FFF;
        font-family: "Public Sans";
        font-size: 16px;
        line-height: 38px;
        min-width: 126px;
        cursor: pointer;
        user-select: none;
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 0 8px;
        gap: 8px;

        svg {
            width: 14px;
            height: 14px;
        }

        &:hover {
            background: #D13800;
        }
    }
}