.root {
    padding-top: 72px;
    background-color: #f8f8f8;

    .container {
        padding: 80px 0;
        width: 1280px;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .title {
        width: 737px;
        color: var(--Neutral-color-100, var(--Woodsmoke, #101010));

        /* H1 */
        -webkit-text-stroke-width: 0.6px;
        -webkit-text-stroke-color: var(--Neutral-color-100, var(--Woodsmoke, #101010));
        font-family: Play;
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        /* 70.4px */
        letter-spacing: -0.32px;
    }

    .cover {
        width: 535px;
        height: 535px;
        background-image: url(./pc.png);
        background-size: 112% auto;
        background-repeat: no-repeat;
        background-position: center;
    }
}

@media screen and (max-width: 992px) {
    .root {
        padding-top: 64px;

        .container {
            padding: 69px 20px 80px;
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-between;
            gap: 48px;
        }

        .wrapper {
            width: 100%;
        }

        .title {
            width: 100%;
            color: var(--Neutral-color-100, var(--Woodsmoke, #101010));
            text-align: center;
            -webkit-text-stroke-width: 0.3px;
            -webkit-text-stroke-color: var(--Neutral-color-100, var(--Woodsmoke, #101010));
            font-family: Play;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%;
            /* 44px */
            letter-spacing: -0.2px;
        }

        .cover {
            width: 330px;
            height: 300px;
            background-size: 102% auto;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}