.tabBar {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  gap: 8px;

  .icon {
    display: block;
    width: 32px;
    visibility: hidden;

    &.right {
      transform: rotate(180deg);
    }
  }

  .title {
    color: #101010;
    text-align: center;

    /* H4 */
    font-family: "Public Sans";
    font-size: 22.5px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    max-width: 272px;

    &:hover {
      color: #fd4c00;
    }
  }

  &.checked {
    .title {
      color: #fd4c00;
    }
    .icon {
      visibility: visible;
    }
  }
}

@media screen and (max-width: 992px) {
  .tabBar {
    .title {
      font-size: 22.4px;
      line-height: 25px;
    }
  }
}
