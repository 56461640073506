.root {
    padding: 4px 24px;
    border-radius: var(--Radius-radius-xs, 4px);
    border: 1px solid var(--Neutral-color-30, #B3B3B3);
    will-change: background-color;
    transition: background-color .3s ease-in-out;
    background-color: #fff;
    cursor: pointer;

    &:hover {
        background-color: var(--Neutral-color-20, #E5E5E5);
    }

    &.active {
        border: 1px solid var(--Primus-Orange-Orange-50, #FD4C00);
        background-color: var(--Neutral-color-10, #F8F8F8);
        color: #101010;
    }

    &.active:hover {
        background-color: var(--Neutral-color-20, #E5E5E5);
    }

    &.disabled {
        cursor: initial;
    }
    &.disabled:hover {
        background-color: #fff;
    }
}