.root {
  background-color: #fff;

  .container {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 100px;
    padding-bottom: 30px;
  }

  .title {
    margin-top: 0;
  }
  .content {
    margin-top: 15px;
    text-align: center;
    .content {
      margin-top: 0;
    }
  }

  .video {
    width: 1400px;
    height: 550px;
    margin-top: 40px;
  }
}

@media screen and (max-width: 992px) {
  .root {
    background-color: #fff;

    .container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 80px;
      padding-bottom: 80px;
    }

    .title {
      margin-top: 16px;
    }
    .content {
      padding: 0 20px;
      box-sizing: border-box;
      margin-top: 24px;
      width: 100%;
      min-height: 154px;
    }

    .videoWrapper {
      position: relative;
      overflow: hidden;
      width: 100%;
      height: 292px;
      margin-top: 40px;
    }

    .video {
      width: 100%;
      height: auto;
      margin-top: 0;
    }
  }
}
