body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.flex {
  display: flex;

  &.middle {
    align-items: center;
  }

  &.between {
    justify-content: space-between;
  }

  &.center {
    justify-content: center;
  }

  &.mobile-view {
    display: none !important;
  }
}

.mobile-view {
  display: none !important;
}


.play {
  font-family: Play;
}

.public-sans {
  font-family: Public Sans;
}

.color-white {
  color: #fff;
}

.color-black {
  color: #101010;
}

@media screen and (max-width: 992px) {
  .pc-view {
    display: none !important;
  }

  .mobile-view {
    display: block !important;
  }

  .flex.mobile-view {
    display: flex !important;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

.h1 {}

.h2 {}

.h3 {
  color: var(--Neutral-color-100, var(--Woodsmoke, #101010));

  /* H3 */
  font-family: "Public Sans";
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
  line-height: 100%;
  /* 32px */
}

.body-1-regular {
  color: #666;
  /* Body 1 Regular */
  font-family: "Public Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
  /* 22.4px */
}

@media screen and (max-width: 992px) {
  .h3 {
    color: var(--Neutral-color-100, var(--Woodsmoke, #101010));

    /* Mobile/H3 */
    font-family: "Public Sans";
    font-size: 22.4px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    /* 26.88px */
  }

  .body-1-regular {
    color: var(--Neutral-color-60, #666);

    /* Mobile/Body1 Regular */
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
    /* 22.4px */
  }
}

@keyframes section-title-fade-in {
  from {
      opacity: 0;
      transform: translateY(30px);
  }

  to {
      opacity: 1;
      transform: translateY(0px);
  }
}

.section-motion {
  will-change: transform, opacity;
  animation: section-title-fade-in 0.4s ease-out;
}

@keyframes fade-in {
  from {
      opacity: 0;
  }

  to {
      opacity: 1;
  }
}

.fade-in {
  will-change: opacity;
  animation: fade-in .2s ease-out;
}