.root {
    padding: 80px 0;
    background-color: #fff;

    .title {
        color: #101010;
        text-align: center;

        /* H2 */
        -webkit-text-stroke-width: 0.6px;
        -webkit-text-stroke-color: #101010;
        font-family: Play;
        font-size: 56px;
        font-weight: 400;
        line-height: 110%;
        /* 61.6px */
        letter-spacing: -0.28px;
    }

    .marquee {
        margin-top: 40px;
        padding: 16px 0;
    }

    .swiperItem {
        width: auto;
        height: 64px;
        display: block;
        margin-right: 60px;
        object-fit: contain;
    }
}

@media screen and (max-width: 992px) {
    .root {
        padding: 80px 0;
    
        .title {
            color: #101010;
            text-align: center;
    
            /* H2 */
            -webkit-text-stroke-width: 0.6px;
            -webkit-text-stroke-color: #101010;
            font-family: Play;
            font-size: 56px;
            font-weight: 400;
            line-height: 110%;
            /* 61.6px */
            letter-spacing: -0.28px;
        }

        .marquee {
            margin-top: 20px;
            padding: 0;
        }
    
        .swiperItem {
            width: auto;
            height: 64px;
            margin-right: 0;

            img {
                height: 32px;
                display: block;
                object-fit: contain;
            }
        }
    }
}