.tabItem {
    width: 1062px;
    box-sizing: border-box;
    border-radius: 16px;
    background: #F7F2E9;
    padding: 24px;
    display: flex;
    align-items: center;
    gap: 40px;

    .cover {
        width: 400px;
        height: 400px;
        border-radius: 16px;
        display: block;
        object-fit: cover;
    }

    .title {
        color: #101010;

        /* H3 */
        font-family: "Public Sans";
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        line-height: 110%;
        width: 83%;
        /* 32px */
    }

    .subTitle {
        margin-top: 24px;
        color: #101010;

        /* Body 1 Regular */
        font-family: "Public Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        width: 534px;
        /* 22.4px */
    }

    .content {
        width: 534px;
        margin-top: 8px;
        color: #666;

        /* Body 1 Regular */
        font-family: "Public Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 22.4px */
    }

    .btn {
        width: 160px;
        line-height: 44px;
        margin-top: 44px;
    }
}

@media screen and (max-width: 992px) {
    .tabItem {
        width: 100%;
        height: 586px;

        box-sizing: border-box;
        border-radius: 8px;
        background: #F7F2E9;
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;

        .cover {
            width: 290px;
            height: 165px;
            border-radius: 8px;
            display: block;
        }

        .container {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
        }

        .title {
            width: 100%;
            color: var(--Neutral-color-100, var(--Woodsmoke, #101010));

            /* Mobile/H3 */
            font-family: "Public Sans";
            font-size: 22.4px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            /* 26.88px */
        }

        .subTitle {
            width: 100%;
            margin-top: 16px;
            color: var(--Neutral-color-100, var(--Woodsmoke, #101010));

            /* Mobile/Body1 Regular */
            font-family: "Public Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            /* 22.4px */
        }

        .content {
            width: 100%;
            margin-top: 8px;
            color: var(--Neutral-color-60, #666);

            /* Mobile/Body1 Regular */
            font-family: "Public Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            /* 22.4px */
        }

        .btn {
            width: 100%;
            line-height: 48px;
            margin-top: auto;
        }
    }
}