.root {
    background-color: #fff;

    .container {
        width: 1280px;
        padding: 80px 0 100px;
        margin: 0 auto;

        .moduleHeader {
            display: flex;
            justify-content: space-between;
            align-items: end;
        }

        .tabWrapper {
            display: flex;
            gap: 8px;
        }

        .cardListWrapper {
            margin-top: 80px;
            display: flex;
            flex-wrap: wrap;
            row-gap: 48px;
        }
    }
}

.mainCard {
    margin-top: 48px;
    display: flex;
    height: 363px;
    padding: var(--Spacing-spacing-8, 8px);
    align-items: center;
    gap: 40px;
    align-self: stretch;

    .cover {
        width: 560px;
        height: 315px;
        flex-shrink: 0;
        object-fit: contain;
        border-radius: 16px;
    }

    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 24px;
        flex: 1 0 0;
    }

    .infoWrapper {
        display: flex;
        align-items: center;
        gap: 24px;
    }

    .textEllipsis {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        line-height: 120%;
    }

    .btn {
        width: 184px;
        line-height: 44px;
    }
}

.newsItemCard {
    padding: 8px;
    border-radius: 16px;
    display: flex;
    width: 395px;
    height: 370px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    flex-shrink: 0;
    cursor: pointer;
    margin-right: 23.5px;

    &:nth-child(3n+3) {
        margin-right: 0;
    }

    .img {
        display: block;
        width: 395px;
        height: 220px;
        border-radius: var(--Radius-radius-m, 8px);

        img {
            display: block;
            width: 395px;
            height: 220px;
        }
    }

    .infoWrapper {
        display: flex;
        align-items: center;
        gap: 24px;
    }

    .title {
        margin-top: 16px;
        overflow: hidden;
        color: var(--Neutral-color-100, var(--Woodsmoke, #101010));
        text-overflow: ellipsis;
        font-family: "Public Sans";
        font-size: 22.4px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        /* 26.88px */
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    &:hover .title {
        text-decoration-line: underline;
    }
}

@media screen and (max-width: 992px) {
    .root {
        background-color: #fff;

        .container {
            width: 100%;
            padding: 80px 20px;
            box-sizing: border-box;

            .moduleHeader {
                display: flex;
                flex-direction: column;
                gap: 40px;
                justify-content: start;
                align-items: center;
            }

            .tabWrapper {
                display: flex;
                gap: 8px;
                flex-wrap: wrap;
                justify-content: center;
            }

            .cardListWrapper {
                margin-top: 80px;
                display: flex;
                flex-direction: column;
                row-gap: 32px;
            }
        }
    }

    .mainCard {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        height: 100%;
        box-sizing: border-box;
        padding: 0 8px;
        align-items: center;
        gap: 16px;
        align-self: stretch;

        .cover {
            width: 100%;
            height: auto;
            flex-shrink: 0;
            object-fit: contain;
            border-radius: 16px;
        }

        .wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
            flex: 1 0 0;
        }

        .infoWrapper {
            display: flex;
            align-items: center;
            gap: 24px;
        }

        .btn {
            width: 100%;
            line-height: 52px;
        }
    }

    .newsItemCard {
        padding: 8px;
        display: flex;
        width: 100%;
        box-sizing: border-box;
        height: auto;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        flex-shrink: 0;
        cursor: pointer;
        margin-right: 0;

        .img {
            display: block;
            width: 100%;
            height: auto;
            border-radius: var(--Radius-radius-m, 8px);

            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }

        .infoWrapper {
            display: flex;
            align-items: center;
            gap: 24px;
        }

        .title {
            margin-top: 16px;
            overflow: hidden;
            color: var(--Neutral-color-100, var(--Woodsmoke, #101010));

            /* Mobile/H4 */
            font-family: "Public Sans";
            font-size: 22.4px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            /* 26.88px */
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
        }

        &:hover .title {
            text-decoration-line: underline;
        }
    }
}