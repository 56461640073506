.root {
    width: 1280px;
    padding-top: 152px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    gap: 64px;

    .body {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .title {
        color: var(--Neutral-color-100, var(--Woodsmoke, #101010));
        text-align: center;

        /* H1 */
        -webkit-text-stroke-width: 0.6px;
        -webkit-text-stroke-color: var(--Neutral-color-100, var(--Woodsmoke, #101010));
        font-family: Play;
        font-size: 64px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        /* 70.4px */
        letter-spacing: -0.32px;
        width: 727px;
    }

    .content {
        width: 790px;
        margin-top: 16px;
        text-align: center;

        a {
            font-weight: 700;
        }
    }

    .btn {
        width: 184px;
        line-height: 52px;
        margin-top: 40px;
    }

    .cover {
        width: 972px;
        height: 580px;
        background-image: url(./cover.png);
        background-size: 100% auto;
        background-position: left top;
        border-radius: 14px;
        border: 1px solid #101010;
        display: block;
        overflow: hidden;
        margin-bottom: -24px;
    }
}

@media screen and (max-width: 992px) {
    .root {
        width: 100%;
        padding-top: 80px;
        padding-bottom: 76px;
        display: flex;
        flex-direction: column;
        align-items: end;
        overflow: hidden;
        gap: 48px;

        .body {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 20px;
        }

        .title {
            color: var(--Neutral-color-100, var(--Woodsmoke, #101010));
            text-align: center;
            -webkit-text-stroke-width: 0.3px;
            -webkit-text-stroke-color: var(--Neutral-color-100, var(--Woodsmoke, #101010));
            font-family: Play;
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 110%;
            /* 44px */
            letter-spacing: -0.2px;
            width: 100%;
        }

        .content {
            width: 100%;
            margin-top: 24px;
            text-align: center;

            span {
                font-weight: 700;
            }
        }

        .btn {
            width: 240px;
            line-height: 52px;
            margin-top: 40px;
        }

        .cover {
            width: 460px;
            height: 286px;
            background-size: auto 100%;
            background-position: right center;
            border-radius: 6.804px;
            border: 1px solid var(--Neutral-color-100, #101010);
            display: block;
            overflow: hidden;
            margin-bottom: 0;
            margin-top: 0;
            position: relative;
            right: 20px;
        }
    }
}