.sectionContent {
    color: rgb(16, 16, 16, 0.6);

    /* Body 1 Regular */
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}

@media screen and (max-width: 992px) {
    .sectionContent {
        color: var(--Neutral-color-60, #666);
        text-align: center;

        /* Mobile/Body1 Regular */
        font-family: "Public Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 22.4px */
    }
}