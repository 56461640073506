.button {
  display: block;

  user-select: none;
  background-color: #fff;
  border-radius: 6px;
  line-height: 36px;
  color: #101010;
  font-family: Public Sans;
  font-weight: 600;
  text-align: center;
  border: 2px solid #fff;
  box-sizing: border-box;
  will-change: background-color, border-color;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  &.clickable {
    cursor: pointer;
  }
  &:hover {
    background-color: #ffe5d5;
    border-color: #ffe5d5;
  }

  &.dark {
    background-color: #101010;
    border-color: #101010;
    color: white;
  }

  &.dark:hover {
    background-color: #282828;
    border-color: #282828;
  }
}

.buttonSecondary {
  cursor: pointer;
  user-select: none;
  box-sizing: border-box;
  border-radius: 6px;
  border: 2px solid #fff;
  background-color: #fd4c00;
  color: #fff;
  font-family: Public Sans;
  font-weight: 600;
  text-align: center;
  line-height: 36px;
  will-change: background-color, border-color;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out,
    color 0.3s ease-in-out;

  &:hover {
    color: #fccdb0;
    border-color: #fccdb0;
  }

  &.dark {
    border: 2px solid #101010;
    background-color: #fff;
    color: #101010;
  }

  &.dark:hover {
    color: #585858;
    border-color: #585858;
  }
}
