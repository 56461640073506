.root {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 120px 0 105px;

  .textLine {
    margin-top: 16px;

    .text {
      color: var(--Neutral-color-100, var(--Woodsmoke, #101010));

      /* Body 1 Regular */
      font-family: "Public Sans";
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      /* 25.2px */
      opacity: 0.6;
    }

    .link {
      display: inline;
      color: var(--Primus-Orange-Orange-50, #fd4c00);

      /* Button */
      font-family: "Public Sans";
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 140%;
      /* 22.4px */
      cursor: pointer;
      padding: 0 16px;

      &:hover {
        color: var(--Primus-Orange-Orange-60, #d13800);
      }
    }
  }

  .cardWrapper {
    width: 1062px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-top: 48px;
  }

  .card {
    padding: 32px 40px;
    background-color: #fff;
    border-radius: var(--Radius-radius-l, 16px);
    display: flex;
    align-items: start;
    position: relative;
    overflow: hidden;
    cursor: pointer;

    .no {
      color: var(--Neutral-color-100, var(--Woodsmoke, #101010));
      -webkit-text-stroke-width: 0.6px;
      -webkit-text-stroke-color: var(
        --Neutral-color-100,
        var(--Woodsmoke, #101010)
      );
      font-family: Play;
      font-size: 56px;
      font-style: normal;
      font-weight: 400;
      line-height: 110%;
      /* 61.6px */
      letter-spacing: -5.6px;
      margin-right: 126px;
      user-select: none;
    }

    .body {
      width: 627px;
      padding: 8px 0;

      .ask {
        line-height: 46px;
        will-change: line-height, padding-bottom;
        transition: line-height 0.2s linear, padding-bottom 0.2s linear;
        color: var(--Neutral-color-100, var(--Woodsmoke, #101010));

        /* H4 */
        font-family: "Public Sans";
        font-size: 22.4px;
        font-style: normal;
        font-weight: 700;
        /* 26.88px */

        &.visible {
          line-height: 120%;
          padding-bottom: 16px;
        }
      }

      .question {
        will-change: height;
        height: 0;
        transition: height 0.3s ease-in-out;
        overflow: hidden;
        color: var(--Neutral-color-100, var(--Woodsmoke, #666));

        /* Body 1 Regular */
        font-family: "Public Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 22.4px */
        .flexC {
          display: flex;
          flex-direction: column;
          gap: 16px;
        }
      }

      .question.questionCalc {
        position: absolute;
        left: 0;
        top: -1000px;
        width: 627px;
        height: auto;
      }
    }

    .btn {
      margin-top: 11px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      border-radius: var(--Radius-radius-m, 8px);
      background: var(--Woodsmoke, #101010);
      margin-left: auto;

      &.up {
        transform: rotate(180deg);
      }
    }

    .questionLink {
      color: var(--Primus-Orange-Orange-50, #fd4c00);
      text-decoration: none;
    }
  }
}

@media screen and (max-width: 992px) {
  .root {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 20px;

    .textLine {
      text-align: center;
      margin-top: 16px;

      .text {
        color: var(--Neutral-color-100, var(--Woodsmoke, #101010));

        /* Body 1 Regular */
        font-family: "Public Sans";
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
        /* 25.2px */
        opacity: 0.6;
      }

      .link {
        color: var(--Primus-Orange-Orange-50, #fd4c00);

        /* Button */
        font-family: "Public Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        /* 22.4px */
        padding: 0 5px;

        &:hover {
          color: var(--Primus-Orange-Orange-60, #d13800);
        }
      }
    }

    .cardWrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-top: 40px;
    }

    .card {
      padding: 24px;
      border-radius: var(--Radius-radius-l, 16px);
      display: flex;
      flex-direction: column;
      align-items: start;
      position: relative;
      overflow: hidden;

      .cardHeader {
        width: 100%;
        display: flex;
        justify-content: space-between;
      }

      .no {
        color: var(--Neutral-color-100, var(--Woodsmoke, #101010));
        -webkit-text-stroke-width: 0.3px;
        -webkit-text-stroke-color: var(
          --Neutral-color-100,
          var(--Woodsmoke, #101010)
        );
        font-family: Play;
        font-size: 35.2px;
        font-style: normal;
        font-weight: 400;
        line-height: 110%;
        /* 38.72px */
        letter-spacing: -3.52px;
        margin-right: 0;
        user-select: none;
      }

      .body {
        width: 100%;
        padding: 0;
        margin-top: 24px;

        .ask {
          color: var(--Neutral-color-100, var(--Woodsmoke, #101010));
          /* H4 */
          font-family: "Public Sans";
          font-size: 22.4px;
          font-style: normal;
          font-weight: 700;
          line-height: 120%;
          /* 26.88px */

          &.visible {
            line-height: 120%;
            padding-bottom: 16px;
          }
        }

        .question {
          will-change: height;
          height: 0;
          transition: height 0.3s ease-in-out;
          overflow: hidden;
          color: var(--Neutral-color-60, #666);

          /* Body 1 Regular */
          font-family: "Public Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          /* 22.4px */
        }

        .question.questionCalc {
          position: absolute;
          left: 0;
          top: -1000px;
          width: 302px;
          height: auto;
        }
      }

      .btn {
        margin-top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 35px;
        height: 35px;
        border-radius: var(--Radius-radius-m, 8px);
        background: var(--Woodsmoke, #101010);
        margin-left: auto;

        &.up {
          transform: rotate(180deg);
        }
      }

      .questionLink {
        color: var(--Primus-Orange-Orange-50, #fd4c00);
        text-decoration: none;
      }
    }
  }
}
