.root {
    width: 1280px;
    margin: 0 auto;
    padding: 80px 0 100px;

    .titleWrapper {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        align-items: end;
    }

    .link {
        display: block;
        color: #101010;
        font-family: "Public Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
        cursor: pointer;
        user-select: none;

        &:hover {
            color: #fd4c00;
        }
    }

    .container {
        margin-top: 48px;
        display: flex;
        justify-content: space-between;
    }

    .cardWrapper {
        background-color: #fff;
        padding: 24px;
        border-radius: 8px;
    }
}

.mainCard {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 8px;
    border: 1px solid #fff;
    background: #FFF;
    cursor: pointer;
    user-select: none;

    &:hover {
        border-color: #FD4C00;
    }

    .cover {
        display: block;
        width: 566px;
        height: 315px;
        border-radius: 8px;
        overflow: hidden;
    }

    .content {
        color: #101010;
        /* H4 */
        font-family: "Public Sans";
        font-size: 22.4px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
        /* 26.88px */
        width: 566px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

.btn {
    display: inline-block;
    padding: 0 24px;
    border-radius: 4px;
    border: 1px solid #B3B3B3;
    margin-right: 24px;

    span {
        color: #666;
        /* Body 1 Regular */
        font-family: "Public Sans";
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        /* 22.4px */
    }
}

.date {
    font-size: 16px;
    color: #666;
    font-family: "Public Sans";
    font-weight: 400;
}

.secondaryCard {
    display: flex;
    width: 582px;
    gap: 24px;
    padding: 24px 0;
    // border-bottom: 1px solid #e5e5e5;
    cursor: pointer;

    &:first-of-type {
        padding-top: 0;
    }

    &:last-of-type {
        border: none;
        padding-bottom: 0;
    }

    .cover {
        width: 216px;
        height: 120px;
        border-radius: 8px;
        overflow: hidden;
    }

    .content {
        width: 342px;

        /* Body 1 Bold */
        font-family: "Public Sans";
        font-size: 16px;
        line-height: 140%;
        /* 22.4px */
        margin-top: 16px;
        color: #000;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }

    &:hover .content {
        text-decoration: underline;
    }
}

@media screen and (max-width: 992px) {
    .root {
        width: 100%;
        margin: 0 auto;
        padding: 40px 0 80px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .titleWrapper {
            margin-top: 16px;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .container {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            justify-content: start;
            padding: 0 20px;
        }

        .cardWrapper {
            background-color: #fff;
            padding: 24px 16px;
            border-radius: 8px;
            margin-top: 22px;
        }

        .link {
            color: var(--Neutral-color-100, var(--Woodsmoke, #101010));
            text-align: right;

            /* Mobile/Button */
            font-family: "Public Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%;
            /* 22.4px */
            letter-spacing: -0.08px;
            margin-top: 16px;
        }
    }

    .mainCard {
        width: 100%;
        padding: 16px 16px 24px 16px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        gap: 16px;
        border-radius: 8px;
        border: 1px solid #fff;
        background: #FFF;
        cursor: pointer;
        user-select: none;

        &:hover {
            border-color: #FD4C00;
        }

        .cover {
            display: block;
            width: 100%;
            height: 178px;
            border-radius: 8px;
            object-fit: cover;
            overflow: hidden;
        }

        .content {
            color: #101010;
            /* H4 */
            font-family: "Public Sans";
            font-size: 22.4px;
            font-style: normal;
            font-weight: 700;
            line-height: 120%;
            /* 26.88px */
            width: 100%;
            -webkit-line-clamp: 3;
        }
    }

    .btn {
        display: inline-block;
        padding: 0 24px;
        border-radius: 4px;
        border: 1px solid #B3B3B3;
        margin-right: 24px;

        span {
            color: #666;
            /* Body 1 Regular */
            font-family: "Public Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            /* 22.4px */
        }
    }

    .date {
        font-size: 16px;
        color: #666;
    }

    .secondaryCard {
        display: flex;
        width: 100%;
        gap: 24px;
        padding: 24px 0;
        // border-bottom: 1px solid #e5e5e5;

        &:first-of-type {
            padding-top: 0;
        }

        &:last-of-type {
            border: none;
            padding-bottom: 0;
        }

        .cover {
            width: 64px;
            height: 64px;
            border-radius: 8px;
            overflow: hidden;
            object-fit: cover;
        }

        .content {
            width: 238px;

            color: var(--Neutral-color-100, var(--Woodsmoke, #101010));

            /* Mobile/Body 1 Bold */
            font-family: "Public Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: 110%;
            /* 17.6px */
            -webkit-line-clamp: 3;
        }

        .smBtn {
            padding: 0 8px;
        }
    }
}