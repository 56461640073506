.root {
    background-color: #101010;
    padding-top: 80px;

    .container {
        width: 1280px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 64px;

        .body {
            display: flex;
            justify-content: space-between;
        }

        .left {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 136px;

            .text {
                color: #878787;
                font-family: "Public Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                /* 22.4px */
            }

            .icons {
                margin-top: 8px;
                display: flex;
                gap: 32px;

                &>a {
                    display: block;
                    cursor: pointer;
                    user-select: none;
                }
            }
        }

        .right {
            display: flex;
            justify-content: end;
            gap: 120px;

            .headLine {
                color: #E5E5E5;
                font-family: "Public Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                margin-bottom: 24px;
                /* 22.4px */
            }

            .linkWrpper {
                display: flex;
                gap: 8px;
                margin-top: 12px;
                align-items: center;
                cursor: pointer;
                user-select: none;
            }

            .link {
                color: #878787;
                font-family: "Public Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }

            .linkWrpper:hover .link {
                color: #fd4c00;
            }

            .linkWrpper:hover path {
                fill: #fd4c00 !important;
            }
        }

        .footer {
            line-height: 64px;
            color: #878787;
            text-align: right;

            /* Body 2 */
            font-family: "Public Sans";
            font-size: 14.4px;
            font-style: normal;
            font-weight: 400;
            /* 17.28px */
        }
    }
}

@media screen and (max-width: 992px) {
    .root {
        background-color: #101010;
        padding-top: 80px;

        .container {
            width: 100%;
            margin: 0 auto;
            display: flex;
            flex-direction: column;
            padding: 0 20px;
            box-sizing: border-box;
            gap: 48px;

            .body {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: start;
            }

            .left {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: start;
                height: auto;

                .leftInner {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: start;
                }

                .text {
                    color: var(--Neutral-color-40, #878787);

                    /* Body 1 Regular */
                    font-family: "Public Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    /* 22.4px */
                    margin-top: 48px;
                }

                .icons {
                    margin-top: 8px;
                    display: flex;
                    gap: 32px;

                    &>div {
                        cursor: pointer;
                        user-select: none;
                    }
                }
            }

            .right {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: start;
                align-items: start;
                column-gap: 60px;
                row-gap: 40px;
                margin-top: 48px;

                & > div:nth-child(2n + 1) {
                    width: 150px;
                }

                .headLine {
                    color: var(--Neutral-color-20, #E5E5E5);

                    /* Body 1 Bold */
                    font-family: "Public Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%;
                    text-align: left;
                    margin-bottom: 0;
                    /* 22.4px */
                }

                .section {
                    margin-top: 16px;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;
                }

                .linkWrpper {
                    display: flex;
                    gap: 8px;
                    margin-top: 0;
                    align-items: center;
                    cursor: pointer;
                    user-select: none;
                }

                .link {
                    color: #878787;
                    font-family: "Public Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                }

                .linkWrpper:hover .link {
                    color: #fd4c00;
                }

                .linkWrpper:hover path {
                    fill: #fd4c00 !important;
                }
            }

            .footer {
                padding: 24px 0;
                width: 100%;
                color: var(--Neutral-color-40, #878787);
                text-align: left;

                /* Body 2 */
                font-family: "Public Sans";
                font-size: 14.4px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                /* 17.28px */
            }
        }
    }
}