.tag {
    border-radius: 4px;
    background: rgba(255, 77, 0, 0.15);
    color: #FD4C00;
    display: inline-block;

    /* Body 1 Regular */
    font-family: "Public Sans";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    padding: 0 8px;
    /* 22.4px */
}