.root {
  background: rgba(248, 248, 248, 1);

  .container {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    padding-top: 80px;
    padding-bottom: 100px;
    .left {
      display: flex;
      flex-direction: column;
      gap: 32px;
      width: 618px;
      .content {
        font-size: 16px;
      }
    }
    .img {
      width: 574px;
    }
  }
}

@media screen and (max-width: 992px) {
  .root {
    .container {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      gap: 40px;
      padding-top: 80px;
      padding-bottom: 80px;
      .left {
        gap: 24px;
        width: 350px;
        .content {
          font-size: 16px;
        }
      }
      img {
        width: 350px;
      }
    }
  }
}
