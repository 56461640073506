.root {
    background: var(--Primus-Beige-Primus-Beige-30, #EFE4D2);
    padding: 120px 0;

    .container {
        width: 1280px;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
    }

    .cardWrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        width: 844px;
    }

    .card {
        background-color: #101010;
        width: 410px;
        height: 406px;
        overflow: hidden;
        border-radius: 16px;

        .wrapper {
            padding: 32px 32px 16px;
            height: 156px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .title {
                color: #FFF;
                /* H4 */
                font-family: "Public Sans";
                font-size: 22.4px;
                font-style: normal;
                font-weight: 700;
                line-height: 120%;
                /* 26.88px */
            }

            .content {
                margin-top: 16px;
                color: rgba(255, 255, 255, 0.60);
                font-family: "Public Sans";
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                /* 22.4px */
            }
        }

        .btn {
            margin-left: auto;
            display: flex;
            justify-content: end;
            align-items: center;
            gap: 8px;
            color: var(--Primus-Orange-Orange-50, #FD4C00);
            cursor: pointer;

            /* Body 2 */
            font-family: "Public Sans";
            font-size: 14.4px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;
            /* 17.28px */

            path {
                stroke: #FD4C00;
            }

            &:hover {
                color: #D13800;

                path {
                    stroke: #D13800;
                }
            }
        }

        .bg {
            display: block;
            width: 410px;
            height: 239px;
            margin-top: -38px;
            pointer-events: none;
        }
    }
}

@media screen and (max-width: 992px) {
    .root {
        background: var(--Primus-Beige-Primus-Beige-30, #EFE4D2);
        padding: 80px 20px;

        .container {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: start;
            gap: 40px;
        }

        .cardWrapper {
            display: flex;
            flex-wrap: wrap;
            gap: 16px;
            width: 100%;
        }

        .card {
            background-color: #101010;
            width: 100%;
            height: auto;
            overflow: hidden;
            border-radius: 16px;

            .wrapper {
                padding: 24px 24px 0;
                height: auto;
                display: flex;
                flex-direction: column;
                justify-content: start;

                .title {
                    width: 230px;
                    color: #FFF;

                    /* H4 */
                    font-family: "Public Sans";
                    font-size: 22.4px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 120%;
                    /* 26.88px */
                }

                .content {
                    margin-top: 16px;
                    width: 270px;
                    color: rgba(255, 255, 255, 0.60);
                    font-family: "Public Sans";
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    /* 22.4px */
                }
            }

            .btn {
                margin-left: 0;
                margin-top: 16px;
                display: flex;
                justify-content: start;
                align-items: center;
                gap: 8px;
                color: var(--Primus-Orange-Orange-50, #FD4C00);
                cursor: pointer;

                /* Body 2 */
                font-family: "Public Sans";
                font-size: 14.4px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                /* 17.28px */

                path {
                    stroke: #FD4C00;
                }

                &:hover {
                    color: #D13800;

                    path {
                        stroke: #D13800;
                    }
                }
            }

            .bg {
                width: 350px;
                height: 204px;
                margin-top: -30px;
                pointer-events: none;
            }
        }
    }
}